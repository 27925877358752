<template>
	<v-menu open-on-hover top offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="mt-n4 ml-1 mr-n10" text v-bind="attrs" fab x-small v-on="on">
				<v-icon :color="color" size="16">mdi-alpha-i-circle-outline</v-icon>
			</v-btn>
		</template>
		<v-card dark :color="color">
			<v-card-text class="pa-3">
				<v-icon size="20" class="mr-2" :class="colorText">mdi-alpha-i-circle-outline</v-icon>
				<span :class="colorText">{{texto}}</span>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	name: "MenuInfo",
	props: {
		texto: String,
		color: { type: String, default: "blue-grey" },
		colorText: { type: String, default: "white--text" },
	},
};
</script>