<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog persistent fullscreen scrollable v-model="dialogCliente">
			<v-card flat elevation="0">
				<ClienteNovo :dialog-cliente="true" @fechar="dialogCliente = false"></ClienteNovo>
			</v-card>
		</v-dialog>
		<DialogConfirmacaoPedido
			:dialogConfirmacaoPedido="dialogConfirmacaoPedido"
			:carregando="carregando"
			@gerar-pedido="gerarPedido()"
			@cancelar-pedido="dialogConfirmacaoPedido = false"
		></DialogConfirmacaoPedido>
		<v-col cols="12" md="7" lg="8" xl="9" order="2" order-md="1">
			<v-card>
				<v-card-title>
					<v-row>
						<v-col cols="12" md="4" class="pr-1">Lista de produtos</v-col>
						<v-spacer />
						<v-col cols="12" md="4" class="px-1 pr-md-2">
							<v-text-field
								v-mask="'##.##'"
								v-model="descontoGeral"
								dense
								outlined
								label="Desconto"
								append-icon="%"
								@change="atualizarDescontoGeral()"
								:disabled="carregando || step > 1"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th style="width: 4%" class="pr-2 pl-0">Código</th>
								<th style="width: 13%" class="px-2">Produto</th>
								<th style="width: 16%" class="px-2">Quantidade</th>
								<th style="width: 7%" class="px-2">Valor</th>
								<th style="width: 7%" class="px-2">Desconto</th>
								<th style="width: 7%" class="px-2">Subtotal</th>
								<th style="width: 1%" class="px-2"></th>
							</tr>
						</thead>
						<tbody>
							<template v-if="produtos.length > 0">
								<tr v-for="(item, i) in produtos" :key="i">
									<td
										style="width: 4%; font-size: 0.9em"
										class="pr-2 pl-0 text-truncate"
									>{{item.idsubproduto}}</td>
									<td style="width: 13%; font-size: 0.9em" class="px-2 text-truncate">
										<v-tooltip open-delay="200" right color="white" content-class="custom-tooltip">
											<template v-slot:activator="{ on }">
												<span class="textoClicavel" v-on="on">{{item.descricao}}</span>
											</template>
											<v-card elevation="0" style="min-width: 350px">
												<v-card-title style="font-size: 1.1em" class="pt-1 text-center">{{item.descricao}}</v-card-title>
												<v-card-text align="center">
													<v-img width="250px" height="200px" v-if="item.linkimagem" :src="item.linkimagem" />
													<v-img width="200px" height="200px" v-else src="../../assets/icons/sem_imagem.png" />
												</v-card-text>
											</v-card>
										</v-tooltip>
									</td>
									<td style="width: 16%" class="px-2">
										<v-text-field
											style="min-width: 150px; font-size: 0.9em"
											class="mb-n3"
											:suffix="item.embalagem"
											persistent-hint
											dense
											v-model.number="item.quantidade"
											v-mask="'########'"
											@change="atualizarProdutoPedido(item, i)"
											:disabled="(carregando || step > 1) || item.idsubproduto == 85768"
										/>
									</td>
									<td
										style="width: 7%; position: relative"
										class="text-truncate px-2 pr-5"
									>{{item.venda | formataDinheiro}}</td>
									<td style="width: 7%" class="text-truncate px-2">
										<v-text-field
											v-mask="'##.##'"
											style="min-width: 50px; max-width: 60px; font-size: 0.9em"
											prefix="%"
											v-model="item.desconto"
											reverse
											dense
											class="mb-n3"
											@change="atualizarProdutoPedido(item, i)"
											:disabled="carregando || step > 1"
										></v-text-field>
									</td>
									<td
										style="width: 7%"
										class="text-truncate px-2"
									>{{calculaSubTotalProdutoTabela(item) | formataDinheiro}}</td>
									<td style="width: 1%" class="px-2">
										<v-btn
											:disabled="carregando || step > 1"
											x-small
											class="error"
											elevation="0"
											fab
											@click="excluirProdutoPedido(i)"
											height="25px"
											width="25px"
											v-if="item.idsubproduto != 85768"
										>
											<v-icon>mdi-minus</v-icon>
										</v-btn>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="7">Nenhum produto adicionado ao pedido</td>
								</tr>
							</template>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="5" lg="4" xl="3" order="1" order-md="2">
			<v-card>
				<v-card-title>* Resumo do pedido</v-card-title>
				<v-card-text>
					<v-stepper v-model="step" vertical flat>
						<v-stepper-step
							:complete="step > 1"
							step="1"
							class="px-0 py-1 font-weight-bold"
							editable
						>Resumo dos produtos</v-stepper-step>
						<v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
							<v-row align="end">
								<v-col>
									<div class="py-2 text-body-1 font-weight-bold">
										<v-icon size="18" class="mr-1 mt-n1">mdi-cart-arrow-down</v-icon>
										{{produtos.length}}
										Produto(s)
									</div>
									<div class="py-2 text-body-1 font-weight-bold">
										<v-icon size="18" class="mr-1 mt-n1">mdi-sale</v-icon>Desconto
									</div>
									<div class="py-2 text-body-1 font-weight-bold">
										<v-icon size="18" class="mr-1 mt-n1">mdi-cart-check</v-icon>Total
									</div>
								</v-col>
								<v-col cols="auto" class="text-right">
									<div class="py-2 text-body-1 font-weight-bold">{{calculaValor() | formataDinheiro}}</div>
									<div
										class="py-2 text-body-1 font-weight-bold"
									>{{(calculaValor()-calculaValorTotal()) | formataDinheiro}}</div>
									<div class="py-2 text-body-1 font-weight-bold">{{calculaValorTotal() | formataDinheiro}}</div>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-btn color="primary" small @click="step = 2, limparPedido()">Próximo</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
						<v-stepper-step
							:complete="step > 2"
							step="2"
							class="px-0 py-1 font-weight-bold"
						>Dados do cliente e vendedor</v-stepper-step>
						<v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
							<v-row align="end">
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">
										<v-row no-gutters>
											<v-col align-self="end">Cliente</v-col>
											<v-col cols="auto">
												<v-btn
													fab
													x-small
													elevation="0"
													color="primary"
													class="mb-1"
													@click="dialogCliente = true"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</div>
									<v-autocomplete
										no-filter
										v-model="pedido.idcliente"
										outlined
										dense
										:search-input.sync="search"
										:items="items"
										:loading="carregando"
										item-text="nome"
										item-value="idcliente"
										class="mb-n10"
										@change="listarEnderecoCliente(), listarFormPagCliente()"
									>
										<template slot="item" slot-scope="{ item }">
											<v-row no-gutters>
												<v-col cols="12" class="text-subtitle-2">{{item.idcliente}} - {{item.nome}}</v-col>
												<v-col
													v-if="item.cpfcnpj.length == 11"
													cols="12"
													class="text-subtitle-2"
												>CPF: {{item.cpfcnpj | formataCpf}}</v-col>
												<v-col v-else cols="12" class="text-subtitle-2">CNPJ: {{item.cpfcnpj | formataCnpj}}</v-col>
											</v-row>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col v-if="pedido.idcliente == 35" cols="12">
									<div class="text-body-1 font-weight-bold">Nome do cliente</div>
									<v-text-field class="mb-n10" outlined dense name="name"></v-text-field>
								</v-col>
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">Endereço de entrega</div>
									<v-select
										v-model="pedido.idlocalentrega"
										class="mb-n10"
										:items="enderecos"
										item-value="idlocalentrega"
										item-text="localentrega"
										outlined
										dense
									></v-select>
								</v-col>
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">
										<v-row no-gutters>
											<v-col align-self="end">Vendedor</v-col>
										</v-row>
									</div>
									<v-autocomplete
										class="mb-n10"
										v-model="pedido.idvendedor"
										outlined
										dense
										:items="vendedores"
										item-text="nome"
										item-value="idcliente"
										:disabled="carregando"
									></v-autocomplete>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-btn small text color="primary" @click="step = 1">Voltar</v-btn>
									<v-btn :disabled="verificaStep2()" small color="primary" @click="step = 3">Próximo</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
						<v-stepper-step
							:complete="step > 3"
							step="3"
							class="px-0 py-1 font-weight-bold"
						>Dados de pagamento</v-stepper-step>
						<v-stepper-content step="3" class="px-2 mx-0 font-weight-bold">
							<v-row align="end" class="d-block">
								<div v-for="(p,i) in pedido.pagamento" :key="i">
									<div class="ma-3 pb-3 rounded" style="border: 1px solid #BBB">
										<v-col cols="12">
											<div class="text-body-1 font-weight-bold">
												Forma de pagamento
												<v-btn
													fab
													x-small
													text
													color="error"
													class="float-right mt-n2"
													v-if="i != 0"
													@click="excluiFrmPgto(i)"
												>
													<v-icon class="font-weight-bold">mdi-close</v-icon>
												</v-btn>
											</div>
											<v-select
												v-model="p.idrecebimento"
												class="mb-n10"
												:items="formaPags"
												item-text="recebimento"
												item-value="idrecebimento"
												outlined
												dense
												@change="listarCondPagCliente(i), p.valor = 0, p.entrada = 0"
											></v-select>
										</v-col>
										<v-col cols="12">
											<div class="text-body-1 font-weight-bold">Valor</div>
											<v-text-field
												v-model.number="p.valor"
												type="number"
												class="mb-n10"
												outlined
												dense
												@change="p.valor = atualizarFrmPgto(p.valor, i), p.entrada = (p.valor * 0.2).toFixed(2)"
												@dblclick="p.valor = (calculaValorTotal() - calculaFrmPgtoTotal(i)).toFixed(2), p.entrada = (p.valor * 0.2).toFixed(2)"
											></v-text-field>
										</v-col>
										<template v-if="condPags.length > 0">
											<template v-if="condPags[i]">
												<v-col cols="12" v-if="condPags[i].length > 0">
													<div class="text-body-1 font-weight-bold">Condição de pagamento</div>
													<v-select
														v-model="p.idcondicao"
														class="mb-n10"
														:items="condPags[i].map(v => {return {...v, parcela: calculaParcela(v, p.valor, p.entrada).descricao}})"
														item-text="parcela"
														item-value="idcondicao"
														outlined
														dense
														@change="calculaEntrada(i)"
													></v-select>
												</v-col>
												<v-col cols="12" v-if="condPags[i].length > 0 && condPags[i].filter(v => v.flagentrada == 'T' && v.idcondicao == p.idcondicao).length > 0">
													<div class="text-body-1 font-weight-bold">Entrada</div>
													<v-text-field v-model.number="p.entrada" type="number" class="mb-n10" outlined dense></v-text-field>
												</v-col>
											</template>
										</template>
									</div>
								</div>
								<v-col cols="12">
									<span>Saldo restante: {{(calculaValorTotal() - calculaFrmPgto()).toFixed(2) | formataDinheiro}}</span>
									<v-btn
										v-if="(calculaValorTotal() - this.calculaFrmPgto()).toFixed(2) != 0"
										:disabled="validarFrmPgto()"
										fab
										x-small
										class="mr-2 mt-n1 float-end"
										color="primary"
										elevation="0"
										@click="adcFrmPgto()"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-btn small text color="primary" @click="step = 2">Voltar</v-btn>
									<v-btn :disabled="verificaStep3()" small color="primary" @click="step = 4">Próximo</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
						<v-stepper-step
							:complete="step > 4"
							step="4"
							class="px-0 py-1 font-weight-bold"
						>Dados complementares</v-stepper-step>
						<v-stepper-content step="4" class="px-2 mx-0 font-weight-bold">
							<v-row align="end">
								<v-col cols="12" class="mb-n3">
									<div class="text-body-1 font-weight-bold pt-1">
										Previsão de entrega
										<MenuInfo
											color="red"
											texto="Este campo é apenas uma sugestão, pode sofrer alteração pelo responsável da usina de concreto."
										/>
									</div>
									<InputDatePicker v-model="pedido.dtprevisao" :outlined="true" :dense="true" />
								</v-col>
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">Frete</div>
									<v-text-field
										class="mb-n10"
										v-model.number="pedido.frete"
										prefix="KM"
										:suffix="`R$ ${(pedido.frete * 0.5).toFixed(2)}`"
										type="number"
										outlined
										dense
										@input="validaFrete()"
									/>
								</v-col>
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">Observação</div>
									<v-textarea class="mb-n10" outlined rows="1" v-model="pedido.observacao"></v-textarea>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-btn small text color="primary" @click="step = 3">Voltar</v-btn>
									<v-btn :disabled="pedido.frete<0" small color="primary" @click="step = 5">Próximo</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
						<v-stepper-step
							:complete="step > 5"
							step="5"
							class="px-0 py-1 font-weight-bold"
						>Confirmação do pedido</v-stepper-step>
						<v-stepper-content step="5" class="px-0 mx-0 font-weight-bold">
							<v-row align="end" v-if="step == 5">
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Cliente</div>
									<div
										v-if="items.length > 0"
										class="py-0 text-caption text-truncate"
									>{{pedido.idcliente}} - {{items.filter(v=>v.idcliente == pedido.idcliente)[0].nome}}</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Endereço</div>
									<div
										v-if="enderecos.length > 0"
										class="py-0 text-caption text-truncate"
									>{{enderecos.filter(v=>v.idlocalentrega == pedido.idlocalentrega)[0].localentrega}}</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Previsão de Entrega</div>
									<div class="py-0 text-caption text-truncate">{{pedido.dtprevisao}}</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Forma de pagamento</div>
									<div class="py-1 text-caption text-truncate">
										<v-simple-table dense>
											<thead>
												<tr>
													<th
														colspan="3"
														class="white--text"
														bgcolor="#023449"
														style="border: #023449 solid 2px; border-right: 0px; border-top-left-radius : 3px"
													>Recebimento</th>
													<th
														colspan="1"
														class="white--text"
														bgcolor="#023449"
														style="border: #023449 solid 2px; border-left: 0px; border-top-right-radius : 3px"
													>Valor</th>
												</tr>
											</thead>
											<tbody>
												<template v-for="(pg, i) in pedido.pagamento">
													<tr :key="i">
														<td
															colspan="3"
															class="text-caption"
															:style="pg.idcondicao && condPags[i].length > 0 ? 'border-left: #023449 1px solid; border-bottom: 0px' : 'border-bottom: #023449 1px solid; border-left: #023449 1px solid'"
														>
															<span
																v-if="formaPags.length > 0"
															>{{formaPags.filter(v => v.idrecebimento == pg.idrecebimento)[0].recebimento}}</span>
														</td>
														<td
															colspan="1"
															class="text-caption"
															:style="pg.idcondicao && condPags[i].length > 0 ? 'border-right: #023449 1px solid; border-bottom: 0px' : 'border-bottom: #023449 1px solid; border-right: #023449 1px solid'"
														>{{pg.valor | formataDinheiro}}</td>
													</tr>
													<tr :key="`${i}_${pg.idcondicao}`" v-if="pg.idcondicao && condPags[i].length > 0">
														<td
															colspan="3"
															class="text-caption"
															style="border-bottom: #023449 1px solid; border-left: #023449 1px solid;"
														>
															<v-icon color="#023449" small class="mb-2 ml-1">mdi-arrow-right-bottom</v-icon>
															<span
																v-if="condPags[i].length > 0"
																class="ml-1"
															>{{calculaParcela(condPags[i].filter(v => v.idcondicao == pg.idcondicao)[0], pg.valor, pg.entrada).descricao}},</span>
														</td>
														<td
															colspan="1"
															class="text-caption"
															style="border-bottom: #022E40 1px solid; border-right: #022E40 1px solid"
														>
															<span class="pl-1 ml-n10">Total: </span>
															<span
																v-if="condPags[i].length > 0"
															>{{calculaParcela(condPags[i].filter(v => v.idcondicao == pg.idcondicao)[0], pg.valor, pg.entrada).total | formataDinheiro}}</span>
														</td>
													</tr>
												</template>
											</tbody>
										</v-simple-table>
									</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div
										class="py-0 text-caption font-weight-bold text-truncate"
									>{{produtos.length}} Produto(s)</div>
									<div class="py-0 text-caption text-truncate">{{calculaValor() | formataDinheiro}}</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Desconto</div>
									<div
										class="py-0 text-caption text-truncate"
									>{{(calculaValor()-calculaValorTotal()) | formataDinheiro}}</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Juros</div>
									<div
										class="py-0 text-caption text-truncate"
									>{{(calculaFrmPgtoComJuros()-calculaValorTotal()-(pedido.frete * 0.5)) | formataDinheiro}}</div>
								</v-col>
								<v-col cols="12" class="mb-n5">
									<div class="py-0 text-caption font-weight-bold text-truncate">Frete</div>
									<div class="py-0 text-caption text-truncate">R$ {{(pedido.frete * 0.5).toFixed(2)}}</div>
								</v-col>
								<v-col cols="12">
									<div class="py-0 text-caption font-weight-bold text-truncate">Total Final</div>
									<div class="py-0 text-caption text-truncate">{{calculaFrmPgtoComJuros() | formataDinheiro}}</div>
								</v-col>
								<v-col cols="12">
									<v-btn small text block color="primary" class="mb-1" @click="step = 4">Voltar</v-btn>
									<v-btn
										:disabled="produtos.length == 0"
										color="primary"
										block
										small
										class="mb-1"
										elevation="0"
										@click="dialogConfirmacaoPedido = true"
									>Gerar venda</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
					</v-stepper>
				</v-card-text>
				<v-card-actions class="px-4 mt-n10">
					<v-row>
						<v-col cols="12">
							<v-btn
								class="text-decoration-underline"
								block
								elevation="0"
								color="primary"
								to="/concreto"
								text
								small
								exact
							>Adicionar mais produtos</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ClienteNovo from "../Cliente/ClienteNovo.vue";
import CardDialog from "../Widgets/CardDialog.vue";
import MenuInfo from "../Widgets/MenuInfo.vue";
import InputDatePicker from "../Widgets/InputDatePicker.vue";
import DialogConfirmacaoPedido from "./Widgets/DialogConfirmacaoPedido.vue";

export default {
	components: {
		ClienteNovo,
		CardDialog,
		MenuInfo,
		InputDatePicker,
		DialogConfirmacaoPedido,
	},
	name: "ConcretoPedidoFaturar",
	data: () => ({
		dialogConfirmacaoPedido: false,
		dialogCliente: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		step: 1,
		carregando: false,
		produtos: [],
		descontoGeral: null,
		pontoRetiradaGeral: null,
		vendedorGeral: null,
		pedido: {
			dtprevisao: `${new Date().toISOString().split("T")[0]}`,
			idlocalentrega: 0,
			frete: 0,
			idvendedor: 0,
			pagamento: [
				{
					idrecebimento: 17,
					valor: 0,
					entrada: 0,
					idcondicao: 0,
					prazovista: "V",
				},
			],
		},
		bomba: {
			idsubproduto: 85768,
			descricao: "SERVIÇOS DE BOMBA",
			embalagem: "UN",
			venda: 15,
			tipo: "B",
			quantidade: 1,
			desconto: 0,
		},
		clientes: [],
		vendedores: [],
		locaisretirada: [],
		enderecos: [],
		formaPags: [],
		condPags: [],
		aguardarBusca: "",
		nomeLimit: 50,
		search: null,

		iconepromocao: "",
		produto: {},
		flagpromocaotodos: true,
		buscaProduto: "",
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
		items() {
			if (this.clientes == null) return;
			this.clientes.forEach((cliente) => {
				if (cliente.nome.length > this.nomeLimit) {
					cliente.nome =
						cliente.nome.slice(0, this.nomeLimit) + "...";
				}
			});
			return this.clientes;
		},
	},
	methods: {
		listarEnderecoCliente() {
			return axios
				.post(`${this.backendUrl}venda/cliente/endereco/listar`, {
					idcliente: this.pedido.idcliente,
				})
				.then((res) => {
					this.enderecos = res.data;
					this.enderecos = this.enderecos.map((v) => {
						return {
							...v,
							localentrega: `${v.enderecoentrega}, Nº ${v.numero} - ${v.bairroentrega} (${v.cidade}-${v.uf})`,
						};
					});
				});
		},
		listarFormPagCliente() {
			return axios
				.post(`${this.backendUrl}venda/cliente/formapagto/listar`, {
					idcliente: this.pedido.idcliente,
				})
				.then((res) => {
					this.formaPags = res.data;
					this.formaPags = this.formaPags.map((v) => {
						return {
							...v,
							recebimento: `${v.idrecebimento} - ${v.recebimento}`,
						};
					});
					this.pedido.pagamento[0].idrecebimento = 17;
					this.pedido.pagamento[0].prazovista = "V";
					this.pedido.pagamento[0].valor =
						this.calculaValorTotal().toFixed(2);
				});
		},
		listarCondPagCliente(posicao) {
			this.condPags[posicao] = [];
			this.pedido.pagamento[posicao].idcondicao = 0;
			this.pedido.pagamento[posicao].prazovista = "V";
			return axios
				.post(`${this.backendUrl}venda/cliente/condpagto/listar`, {
					idrecebimento: this.pedido.pagamento[posicao].idrecebimento,
				})
				.then((res) => {
					if (res.data) {
						this.condPags[posicao] = res.data;
						this.pedido.pagamento[posicao].prazovista =
							this.formaPags.find(
								(u) =>
									u.idrecebimento ===
									this.pedido.pagamento[posicao].idrecebimento
							).prazovista;
					}
					this.pedido.pagamento[posicao].valor = (
						this.calculaValorTotal() -
						this.calculaFrmPgtoTotal(posicao)
					).toFixed(2);
				});
		},
		listarClientes(val) {
			clearTimeout(this.aguardarBusca);
			this.aguardarBusca = setTimeout(() => {
				return axios
					.post(`${this.backendUrl}cliente/listar`, {
						limit: 30,
						offset: 0,
						busca: val || null,
					})
					.then((res) => {
						this.clientes = res.data.lista;
					});
			}, this.timeLimit);
		},
		listarVendedores() {
			return axios
				.post(`${this.backendUrl}cliente/listar`, {
					limit: 999999,
					offset: 0,
					tipo: "'V'",
					busca: null,
				})
				.then((res) => {
					this.vendedores = res.data.lista;
					this.vendedores.forEach((vendedor) => {
						vendedor.nome = `${vendedor.idcliente} - ${vendedor.nome}`;
						if (vendedor.nome.length > this.nomeLimit) {
							vendedor.nome =
								vendedor.nome.slice(0, this.nomeLimit) + "...";
						}
					});
				});
		},
		// Final das funções de listagem
		// Atualiza array de produtos no LocalStorage
		atualizaArrayProdutos() {
			this.validaBomba();
			localStorage.setItem(
				"pedidoConcreto",
				JSON.stringify(this.produtos)
			);
			if (this.produtos.length == 0) {
				this.$router.push("/concreto");
			}
			this.produtos.sort((a, b) => a.idsubproduto - b.idsubproduto);
		},
		// Função chamada sempre um produto tem algum campo alterado
		atualizarProdutoPedido(produto, i) {
			if (produto.quantidade > 0) {
				this.produtos.splice(i, 1, produto);
			} else {
				this.produtos.splice(i, 1);
			}
			this.atualizaArrayProdutos();
		},
		// Função chamada para limpar informações do pedido* item.quantidade
		limparPedido() {
			this.condPags = [];
			this.formaPags = [];
			this.enderecos = [];
			this.pedido = {
				idlocalentrega: 0,
				dtprevisao: new Date().toISOString().split("T")[0],
				frete: 0,
				pagamento: [
					{ idrecebimento: 17, valor: 0, entrada: 0, idcondicao: 0 },
				],
			};
		},
		// Função chamada para excluir produto do array de produtos
		excluirProdutoPedido(i) {
			if (i >= 0 && i < this.produtos.length) {
				this.produtos.splice(i, 1);
				this.atualizaArrayProdutos();
			}
		},
		// Função para atualizar o desconto em todos os produtos
		atualizarDescontoGeral() {
			if (this.descontoGeral >= 0) {
				this.produtos.forEach((produto) => {
					produto.desconto = this.descontoGeral;
				});
				this.atualizaArrayProdutos();
			}
		},
		// Função para adicionar uma nova forma de pagamento padrão em dinheiro (somente quando à saldo pendente)
		adcFrmPgto() {
			const saldoPendente =
				this.calculaValorTotal() -
				this.calculaFrmPgtoTotal(this.pedido.pagamento.length);
			if (saldoPendente > 0) {
				this.pedido.pagamento.push({
					prazovista: "V",
					idrecebimento: 17,
					valor: saldoPendente.toFixed(2),
					entrada: 0,
					idcondicao: 0,
				});
			}
		},
		// Função para excluir uma nova forma de pagamento
		excluiFrmPgto(i) {
			if (
				i >= 0 &&
				i < this.pedido.pagamento.length &&
				i < this.condPags.length
			) {
				this.pedido.pagamento.splice(i, 1);
				this.condPags.splice(i, 1);
			}
		},
		// Função para bloquear que o valor da forma de pagamento seja superior ao saldo pendente
		atualizarFrmPgto(valor, i) {
			const saldoPendente = (
				this.calculaValorTotal() - this.calculaFrmPgtoTotal(i)
			).toFixed(2);
			if (valor > saldoPendente || valor <= 0) {
				return saldoPendente;
			} else {
				return parseFloat(valor.toFixed(2));
			}
		},
		// Função para validar que o valor da forma de pagamento seja diferente de 0 ou null
		validarFrmPgto() {
			return this.pedido.pagamento.some(
				(v) => v.valor === 0 || v.valor === null
			);
		},
		// Função que calcula valor do pedido sem desconto
		calculaValor() {
			const valor = this.produtos.reduce((total, produto) => {
				return total + produto.venda * produto.quantidade;
			}, 0);

			return parseFloat(valor.toFixed(2));
		},
		// Função que calcula valor do pedido com desconto
		calculaValorTotal() {
			const valor = this.produtos.reduce((total, produto) => {
				const desconto = produto.desconto ? produto.venda * (produto.desconto / 100) : 0;
				const valorComDesconto = (produto.venda - desconto) * produto.quantidade;
				return total + valorComDesconto;
			}, 0);

			return parseFloat(valor.toFixed(2));
		},
		// Função que calcula valor total informado nas formas de pagamento
		calculaFrmPgto() {
			if (!this.pedido.pagamento) {
				return 0;
			}

			const valor = this.pedido.pagamento.reduce((total, pagamento) => {
				return total + (parseFloat(pagamento.valor) || 0);
			}, 0);

			return parseFloat(valor.toFixed(2));
		},
		// Função que calcula valor informado nas formas de pagamento, menos a forma de pagamento da prosição informada
		calculaFrmPgtoTotal(i) {
			if (!this.pedido.pagamento) {
				return 0;
			}

			const arr = this.pedido.pagamento.slice();
			arr.splice(i, 1);

			const valor = arr.reduce((total, pagamento) => {
				return total + (parseFloat(pagamento.valor) || 0);
			}, 0);

			return parseFloat(valor.toFixed(2));
		},
		// Função que calcula valor informado nas formas de pagamento, considerando os valores a prazo.
		calculaFrmPgtoComJuros() {
			let valor = 0;
			if (this.pedido.pagamento) {
				this.pedido.pagamento.forEach((pagamento, i) => {
					const formaPagamento = this.formaPags.find(
						(f) => f.idrecebimento === pagamento.idrecebimento
					);
					const flag = formaPagamento?.prazovista;

					if (flag === "V") {
						valor += parseFloat(pagamento.valor) ?? 0;
					} else if (this.condPags[i]?.length > 0) {
						const condicaoPagamento = this.condPags[i].find(
							(c) => c.idcondicao === pagamento.idcondicao
						);
						const totalParcela = this.calculaParcela(
							condicaoPagamento,
							pagamento.valor,
							pagamento.entrada
						)?.total;
						valor += parseFloat(totalParcela) ?? 0;
					}
				});
			}
      return ((parseFloat(valor))+(parseFloat(this.pedido.frete*0.5))).toFixed(2);
			// return parseFloat((valor.toFixed(2))+((this.pedido.frete*0.5).toFixed(2)));
		},
		// Função que calcula as condições de pagamentos para o filtro de parcelas
		calculaParcela(condicaoPagamento, valorTotal, valorEntrada) {
			const { flagentrada, peracrvenda, qtdPagamento } = condicaoPagamento;
			const entrada = flagentrada === "T" ? "c/ entrada" : "s/ entrada";
			valorEntrada = parseFloat(valorEntrada);

			// function calcularParcelaJurosSemEntrada() {
			// 	const parcela = valorTotal / qtdPagamento;
			// 	const total = parcela * qtdPagamento;
			// 	return {
			// 		descricao: `${qtdPagamento} x R$ ${parcela.toFixed(
			// 			2
			// 		)} ${entrada}`,
			// 		parcela,
			// 		total: total,
			// 	};
			// }

      function calcularParcelaJurosSemEntrada() {
        // const parcela = valorTotal / qtdPagamento;
        // const total = parcela * qtdPagamento;

        const parcela = (((1 + (parseFloat(peracrvenda) / 100))**(1/30)) - 1) * 100;

        const total = valorTotal * ((1+(parcela/100))**(qtdPagamento*30));



        console.log(parcela);
        console.log(total);
        console.log(valorTotal);
        return {
          descricao: `${qtdPagamento} x R$ ${parcela.toFixed(
              2
          )} ${entrada}`,
          parcela,
          total: total,
        };
      }

			function calcularParcelaJurosComEntrada() {
				const a =
					(1 + parseFloat(peracrvenda) / 100) ** (qtdPagamento - 1) *
					(parseFloat(peracrvenda) / 100);
				const b =
					(1 + parseFloat(peracrvenda) / 100) ** (qtdPagamento - 1) -
					1;
				const parcela =
					Math.ceil((valorTotal - valorEntrada) * (a / b) * 100) /
					100;
				const total = valorEntrada + parcela * (qtdPagamento - 1);
				return {
					descricao: `1 x R$ ${valorEntrada.toFixed(2)} + ${
						qtdPagamento - 1
					} x R$ ${parcela.toFixed(2)} ${entrada}`,
					parcela: parcela,
					total: total,
				};
			}

			function calcularParcelaSemJurosSemEntrada() {
				const parcela = valorTotal / qtdPagamento;
				return {
					descricao: `${qtdPagamento} x R$ ${parcela.toFixed(
						2
					)} ${entrada}`,
					parcela,
					total: valorTotal,
				};
			}

			function calcularParcelaSemJurosComEntrada() {
				const parcela =
					(valorTotal - valorEntrada) / (qtdPagamento + 1);
				return {
					descricao: `1 x R$ ${valorEntrada.toFixed(
						2
					)} + ${qtdPagamento} x R$ ${parcela.toFixed(2)} ${entrada}`,
					parcela,
					total: valorTotal,
				};
			}

			if (peracrvenda > 0 && flagentrada === "F") {
				return calcularParcelaJurosSemEntrada();
			}

			if (peracrvenda > 0 && flagentrada === "T") {
				return calcularParcelaJurosComEntrada();
			}

			if (peracrvenda <= 0 && flagentrada === "F") {
				return calcularParcelaSemJurosSemEntrada();
			}

			if (peracrvenda <= 0 && flagentrada === "T") {
				return calcularParcelaSemJurosComEntrada();
			}
		},
		// Função que calcula o valor de entrada conforme forma de recebimento
		calculaEntrada(posicao) {
			const condicaoPagamento = this.condPags[posicao].find(
				(u) =>
					u.idcondicao === this.pedido.pagamento[posicao].idcondicao
			);
			const flagEntrada = condicaoPagamento.flagentrada;
			const valorTotal = this.calculaValorTotal();
			const valorPago = this.calculaFrmPgtoTotal(posicao);
			const taxaEntrada = 0.2;
			const entrada =
				flagEntrada === "T"
					? ((valorTotal - valorPago) * taxaEntrada).toFixed(2)
					: 0;

			this.pedido.pagamento[posicao].entrada = entrada;
		},
		// Função que calcula o subtotal do produto na tabela com desconto x quantidade
		calculaSubTotalProdutoTabela(produto) {
			const desconto = produto.desconto
				? produto.venda * (produto.desconto / 100)
				: 0;
			const subtotal = (produto.venda - desconto) * produto.quantidade;
			return parseFloat(subtotal.toFixed(2));
		},
		verificaStep2() {
			return !this.pedido.idcliente || !this.pedido.idvendedor;
		},
		verificaStep3() {
			const pagamentoPrazoSemCondicao = this.pedido.pagamento.some((pagamento) => pagamento.prazovista === "P" && pagamento.idcondicao === 0);
			const valorPendente = (this.calculaValorTotal() - this.calculaFrmPgto()).toFixed( 2) != 0;
      // console.log('calculaValorTotal()',this.calculaValorTotal());
      // console.log('calculaFrmPgto()',this.calculaFrmPgto());
      // console.log('calculo',(this.calculaValorTotal() - this.calculaFrmPgto()).toFixed( 2))
      // console.log('pagamentoPrazoSemCondicao',pagamentoPrazoSemCondicao);
      // console.log('valorPendente',valorPendente);

			return pagamentoPrazoSemCondicao || valorPendente;
		},
		// Valida bomba de serviço
		validaBomba() {
			const idBomba = 85768;
			this.produtos = this.produtos.filter(
				(produto) => produto.idsubproduto !== idBomba
			);
			const temBombeavel = this.produtos.some(
				(produto) => produto.tipo === "B"
			);
			if (temBombeavel) {
				const quantidadeBombeavel = this.produtos
					.filter((produto) => produto.tipo === "B")
					.reduce((total, produto) => total + produto.quantidade, 0);
				this.bomba.quantidade = Math.max(quantidadeBombeavel, 10);
				this.produtos.push(this.bomba);
			}
		},
		// Função trata e monta informações do corpo da requisição do pedido
		montaPedido() {
			this.produtos.forEach((v) => {
				v.desconto = parseFloat(v.desconto);
			});
			this.pedido.pagamento.forEach((v) => {
				v.entrada = parseFloat(v.entrada);
				v.valor = parseFloat(v.valor);
			});

			return {
				idcliente: this.pedido.idcliente,
				identrega: this.pedido.idlocalentrega,
				idvendedor: this.pedido.idvendedor,
				cliente:
					this.pedido.nome ||
					this.items.find(
						(v) => v.idcliente === this.pedido.idcliente
					).nome,
				observacao: this.pedido.observacao || " ",
				pagamento: this.pedido.pagamento,
				dtprevisao: `${this.pedido.dtprevisao}T00:00`,
				frete: this.pedido.frete * 0.5,
				valor: this.calculaValorTotal(),
			};
		},
		// Função que gera um novo pedido
		gerarPedido() {
			this.carregando = true;
			const cabecalho = this.montaPedido();
			return axios
				.post(`${this.backendUrl}concreto/gerar/pedido`, {
					cabecalho,
					produtos: this.produtos,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data != 0) {
						localStorage.removeItem("pedidoConcreto");
						this.$router.push(`/concreto/pedido/get/${res.data}`);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível faturar o pedido!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Não foi possível faturar o pedido!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		// Não permite digitar uma Km de frete menor que zero
		teste() {
			if (this.pedido.frete < 0) {
				this.pedido.frete = 0;
			}
		},
    validaFrete() {
      if (parseFloat(this.pedido.frete) < 0 || isNaN(this.pedido.frete) || typeof this.pedido.frete != "number") {
        setTimeout(() => ( this.pedido.frete = 0),100);
      }
    },
		async init() {
			const json = localStorage.getItem("pedidoConcreto");
			const produtos = JSON.parse(json);
			if (produtos) {
				this.produtos = produtos;
				this.produtos.sort((a, b) => a.idsubproduto - b.idsubproduto);
			}
			if (this.produtos.length == 0) {
				this.$router.push("/concreto");
			}
			await this.listarVendedores();
			this.atualizaArrayProdutos();
		},
	},
	watch: {
		search: function (val) {
			this.listarClientes(val);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.custom-tooltip {
	opacity: 1 !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
		0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	padding: 10px !important;
}
.textoClicavel:hover {
	font-weight: bolder;
}
</style>