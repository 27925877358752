<template>
	<v-dialog v-model="dialogConfirmacaoPedido" max-width="500">
		<v-card>
			<v-card-title class="text-h5">Confirmar geração do pedido de venda?</v-card-title>
			<v-divider />
			<div class="text-center py-3">
				<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
			</div>
			<v-divider />
			<v-card-actions class="pa-3">
				<v-spacer />
				<v-btn :loading="carregando" text color="primary" elevation="0" @click="cancelar">Cancelar</v-btn>
				<v-btn :loading="carregando" color="primary" elevation="0" @click="gerar">Gerar</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialogConfirmacaoPedido: {
			type: Boolean,
			required: true,
		},
		carregando: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		cancelar() {
			this.$emit("cancelar-pedido");
		},
		gerar() {
			this.$emit("gerar-pedido");
		},
	},
};
</script>